html,
body,
div,
nav {
    scrollbar-width: thin;
    scrollbar-color: #b0bec5 hsl(0, 0%, 100%);
}

::-webkit-scrollbar {
    width: 11px;
}

::-webkit-scrollbar-track {
    background: hsl(0, 0%, 100%);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #b0bec5;
    border-radius: 6px;
    border: 3px solid hsl(0, 0%, 100%);
}